<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="4" v-for="(statistic, index) in statistics" v-if="statistic.account_division_current" :v-key="index">
          <v-card :loading="table.loading">
            <v-card-text class="d-flex justify-space-between align-center">
              <div>
                <div class="d-flex align-center">
                  <span class="ml-2 text--accent-2">عدد المحاضرات:</span>
                  <h2 class="font-weight-semibold">
                    {{ statistic.absence_count }}
                  </h2>
                </div>
                <div class="d-flex align-center">
                  <span class="ml-2">الصف والشعبة:</span>
                  <h4 class="font-weight-semibold">
                    {{
                      ((statistic.account_division_current.class_name) ? (statistic.account_division_current.class_name) : "") + '__' + ((statistic.account_division_current.leader) ? (statistic.account_division_current.leader) : "")
                    }}
                  </h4>
                </div>
              </div>

              <v-icon size="30" color="primary" class="rounded-0">
                fa-qrcode
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text">تفاصيل الغيابات والحضور</h1>
        <h3 class="text-center mb-3 subtitle-4 black--text">{{ $route.params.account_name }}</h3>
        <v-row class="mt-2">
          <v-col md="4" cols="12">
            <v-select
              v-model="tableModifier.isPaid"
              :items="isPaidSelectItems"
              dense
              label="اختيار نوع الفاتورة"
              outlined
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col md="4" cols="12">
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="tableModifier.start_date"
                  dense
                  label="من"
                  outlined
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12">
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="tableModifier.end_date"
                  dense
                  label="الى"
                  outlined
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col md="4" cols="12">
            <v-btn
              tile
              class="ml-2"
              color="success"
              :loading="xlsxData.downloadLoading"
              @click="getAllTeacherDataAxios"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :items="table.teacherData"
              :options.sync="tableOptions"
              :server-items-length="table.totalTeacherData"
              :loading="table.loading"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
              <template slot="item.absence_id" slot-scope="props">
                {{ (tableOptions.page - 1) * tableOptions.itemsPerPage + props.index + 1 }}
              </template>
              <template v-slot:item.absence_type="{ item }">
                <v-chip v-if="item.absence_type == 'absence'" color="#E57373" dark> غياب </v-chip>
                <v-chip v-else-if="item.absence_type == 'vacation'" color="#455A64" dark> اجازة </v-chip>
                <v-chip v-else color="primary" dark> حضور </v-chip>
              </template>
              <template v-slot:item.account_division_current="{ item }">
                <span v-if="item.account_division_current.class_name !== ''">{{
                  item.account_division_current.class_name + '__' + item.account_division_current.leader
                }}</span>
                <span v-else>حضور يومي</span>
              </template>
              <template v-slot:item.isPaid="{ item }">
                <span v-if="item.isPaid">مدفوعة</span>
                <span v-else>غير مدفوعة</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                      fa-trash
                    </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  data() {
    return {
      deleteItemLoading: false,

      menuStartDate: false,

      menuEndDate: false,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: `غيابات ${this.$route.params.account_name}`,
        autoWidth: true,
        bookType: 'xlsx',
      },

      statistics: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      allTeacherData: [],

      isPaidSelectItems: [
        { text: 'غير مدفوعة', value: false },
        { text: 'مدفوعة', value: true },
      ],

      tableModifier: {
        isPaid: false,
        start_date: null,
        end_date: null,
      },

      table: {
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'absence_id',
          },
          {
            text: 'النوع',
            sortable: false,
            value: 'absence_type',
          },
          { text: 'الصف والشعبة', sortable: false, value: 'account_division_current' },
          { text: 'الحسابات', sortable: false, value: 'isPaid' },
          { text: 'الدرس', sortable: false, value: 'absence_class_study' },
          { text: 'الاضافة', sortable: false, value: 'insertedBy' },
          { text: 'التاريخ', sortable: false, value: 'absence_date' },
          { text: 'الوقت', sortable: false, value: 'created_at' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.getTeacherDataAxios()
      },
      deep: true,
    },

    'tableModifier.isPaid': {
      handler() {
        this.getTeacherDataAxios()
      },
      // deep: true,
    },

    'tableModifier.start_date': {
      handler() {
        this.getTeacherDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getTeacherDataAxios()
      },
      // deep: true,
    },
  },

  // async mounted() {
  //   this.getTeacherDataAxios()
  // },
  methods: {
    async getTeacherDataAxios() {
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getAbsenceForSpecificTeacher({
        account_id: this.$route.params.account_id,
        study_year,
        isPaid: this.tableModifier.isPaid,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.table.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.teacherData = response.data.results.data
        this.table.totalTeacherData = response.data.results.count
        this.statistics = response.data.results.statistics
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await Api.removeAbsenceStudent(this.deletedItem._id)

      if (response.status === 401) {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getTeacherDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllTeacherDataAxios() {
      this.xlsxData.downloadLoading = true

      let { search } = this.table
      let { page } = this.tableOptions
      if (!search) {
        search = ''
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getAllAbsenceForSpecificTeacher({
        account_id: this.$route.params.account_id,
        study_year,
        isPaid: this.tableModifier.isPaid,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
      })

      if (response.status === 401) {
        this.xlsxData.downloadLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.xlsxData.downloadLoading
        this.allTeacherData = response.data.results
        this.handleDownload()
      }
    },

    async handleDownload() {
      const studentsData = this.allTeacherData
        .filter(p => p.absence_type)
        .map(item => {
          const temp = { ...item }
          if (temp.absence_type === 'presence') {
            const res = {
              created_at: temp.created_at,
              absence_date: temp.absence_date,
              account_division_current_for_excel: temp.account_division_current_for_excel,
              absence_type: (temp.absence_type = 'حضور'),
            }

            return res
          }
          if (temp.absence_type === 'vacation') {
            const res = {
              created_at: temp.created_at,
              absence_date: temp.absence_date,
              account_division_current_for_excel: temp.account_division_current_for_excel,
              absence_type: (temp.absence_type = 'اجازة'),
            }

            return res
          }

          const res = {
            created_at: temp.created_at,
            absence_date: temp.absence_date,
            account_division_current_for_excel: temp.account_division_current_for_excel,
            absence_type: (temp.absence_type = 'غياب'),
          }

          return res
        })
      this.xlsxData.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['النوع', 'الصف والشعبة', 'التاريخ', 'تاريخ الانشاء']
        const filterVal = ['absence_type', 'account_division_current_for_excel', 'absence_date', 'created_at']

        // const { list } = this
        const data = this.formatJson(filterVal, studentsData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

table.v-table tbody td {
  color: black;
}
</style>
